@import 'reset';
@import 'fontface';

body {
  background-color: #112b39;
  overflow-x: hidden;

  scrollbar-color: #feb9b3;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    background-color: #f6f4f5;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #feb9b3;
    border-radius: 0px;
  }
}
