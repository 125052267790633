/* Karla Regular */
@font-face {
  font-family: 'Karla';
  src: url('../fonts/Karla/Karla-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

/* Karla Medium */
@font-face {
  font-family: 'Karla';
  src: url('../fonts/Karla/Karla-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

/* Karla Bold */
@font-face {
  font-family: 'Karla';
  src: url('../fonts/Karla/Karla-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

/* Lunchtype24 Medium */
@font-face {
  font-family: 'Lunchtype24';
  src: url('../fonts/Lunchtype/lunchtype24-medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
