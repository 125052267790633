html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: Karla;
  src: url("Karla-Regular.357a1c79.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Karla;
  src: url("Karla-Medium.3bebbc3f.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Karla;
  src: url("Karla-Bold.fad166cc.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Lunchtype24;
  src: url("lunchtype24-medium.6d889c38.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

body {
  scrollbar-color: #feb9b3;
  scrollbar-width: thin;
  background-color: #112b39;
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

body::-webkit-scrollbar-track {
  background-color: #f6f4f5;
}

body::-webkit-scrollbar-thumb {
  background-color: #feb9b3;
  border-radius: 0;
}

/*# sourceMappingURL=index.c4609265.css.map */
